
import { useStore } from "vuex";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ListAttributes } from "@/core/model/ListAttriibutes";
import { Actions } from "@/store/enums/StoreEnums";
//import ProfilingModalNew from "@/views/profiling/ProfilingModalNew.vue";
import { displayErrors } from "@/core/helpers/errors";
import { PaymentPlan } from "@/core/model/PaymentPlan";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "payment-plans-listing",
  components: {
    //ProfilingModalNew,
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Nombre",
        key: "name",
        sortable: true,
        sortingField: "name",
      },
      {
        name: "Precio Mínimo",
        key: "minimal_price",
        sortable: true,
        sortingField: "minimal_price",
      },
      {
        name: "Cuotas",
        key: "max_quotas",
        sortable: true,
        sortingField: "max_quotas",
      },
      {
        name: "TEA",
        key: "interest_rate",
        sortable: true,
        sortingField: "interest_rate",
      },
      {
        name: "Creado",
        key: "created_at",
        sortable: true,
        sortingField: "created_at",
        width: "160px",
      },
      {
        name: "Creado por",
        key: "created_by",
        sortable: true,
        sortingField: "created_by",
      },
      {
        name: "Estado",
        key: "active",
        sortable: true,
        sortingField: "active",
        width: "80px",
      },
      {
        name: "Acciones",
        key: "actions",
        sortable: false,
        width: "80px",
      },
    ]);
    const loading = ref(false);
    const tableData = ref<Array<PaymentPlan>>([]);
    const rowsPerPage = ref(10);
    const currentPage = ref(1);
    const total = ref(0);
    let sortOrder = "asc";
    let sortField = "name";
    let currentSortColumn = ref("dateasc");
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Planes", ["Planes de pago"]);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
      } as ListAttributes;
      loading.value = true;
      store
        .dispatch(Actions.PAYMENT_PLANS_LIST, params)
        .then(() => {
          tableData.value.splice(
            0,
            tableData.value.length,
            ...store.state.PaymentPlansModule.list.items
          );
          loading.value = false;
          rowsPerPage.value = store.state.PaymentPlansModule.list.limit;
          currentPage.value = store.state.PaymentPlansModule.list.page;
          total.value = store.state.PaymentPlansModule.list.total;
        })
        .catch(() => {
          displayErrors(store, "PaymentPlansModule");
          loading.value = false;
        });
    };

    const searchItems = () => {
        if (timerSearch) {
            clearTimeout(timerSearch);
            timerSearch = null;
        }
        timerSearch = setTimeout(() => {
            loadData();
        }, 800);
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadData();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadData();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadData();
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      loading,
      rowsPerPage,
      currentPage,
      total,
      currentSortColumn,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
    };
  },
});
